export const verifyOk = async (response: Response) => {
  if (!response.ok) {
    const body = await response.json();
    throw body;
  }

  return response;
};

export const storageUrl = (file: string) => {
  return `/api/storage/${file}`  
}